<template>
  <div class="help">
    help
  </div>
</template>

<script>
export default {
  name: 'home',
  props: {
    
  }
}
</script>

<style lang="scss">
  .help {
    
  }
</style>
